<template>
    <div class="gmx_textarea">
        <div class="textarea_container">
            <textarea :value="modelValue" :placeholder="hintMsg" :readonly="type ==='read'" @input="updateValue" :class="error !== '' ? 'error-active' : ''" :rows=props.rows>
            </textarea>
        </div>
        <span v-if="error !== ''" class="error">{{ error }}</span>
    </div>
</template>

<script setup>

import { defineProps, defineEmits, ref} from 'vue';

const props = defineProps({
    id : {
        type: String,
        default : ''
    },
    hintMsg : {
        type: String,
        default : ''
    },
    valid : {
        type : Function,
        default : null
    },
    modelValue : {
        type : String,
        default : ''
    },
    type : {
      type: String,
      default: ''
    },
    rows : {
      type:String,
      default : '10'
    }
});
const error = ref('');
const emit = defineEmits(['focus', 'input']);

const updateValue = (event) => {
  emit('update:modelValue', event.target.value); // 이벤트 발생하여 값을 부모 컴포넌트로 전달

  //valid 체크
  if ( props.valid) {
    error.value = props.valid(event.target.value);
  } else {
    error.value = '';
  }
}

</script>

<style scoped lang="scss" >

  .gmx_textarea {
    width: 100%;
    .textarea_container {
      display: flex;
      align-items: center;
    }
    textarea {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 8px;
      border: 1px solid $gmx-box-br-color;
      border-radius: $border-radius1;
      font-size: $font-size2;
      &:focus {
        border: 1px solid $gmx-main-color;
      }
      &::placeholder {
        color: $gmx-box-sub-br-color;
        font-size: $font-size2;
      }
      &:hover {
        border: 1px solid $gmx-box-br-color;
      }
      &:disabled {
        background-color: $gmx-box-bg-color;
        border: 1px solid $gmx-box-br-color;
      }
      &.error-active {
        border: 1px solid $alert-color1;
      }
      &[type="date"] {
        position: relative;
        &::-webkit-clear-button {
          display: none;
        }
        &::-webkit-inner-spin-button {
          display: none;
        }
        &::-webkit-calendar-picker-indicator {
          background-color: transparent;
          opacity: 0;
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          display: block;
          width: 16px;
          height: 16px;
          background-image: url(@/assets/img/icn_cal.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          z-index: 0;
        }
      }
    }
    .error {
      display: inline-block;
      margin-top: 6px;
      font-size: $font-size1;
      color: $alert-color1;
    }
  }
</style>