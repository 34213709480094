<template>
  <div class="gmx_selbox" :class="(localError !== '' ? 'error-active' : '') + ' ' + cl">
    <div class="selbox_container">
      <div class="title" @click="toggleOptions" 
      :class="(isOpen ? 'active' : '') + (isRead ? ' read' : '') + (computedSelectValue.getSelectLabel().label === '전체' ? ' empty' : '')">
          {{ computedSelectValue.getSelectLabel().label }}
      </div>
      <ul v-if="!isRead" :id="id" v-show="isOpen" class="items">
        <li v-if="items.length == 0" @click="computedSelectValue.changeOption({ id: 'first', label: computedSelectValue.getSelectLabel().label})">
            {{ computedSelectValue.getSelectLabel().label }}
        </li>
       
        <li class="item" v-for="item in rItems" :key="item.value"  @click.stop="computedSelectValue.changeOption(item)">

          <input type="checkbox" class="gmx_check_box" v-model="item.checked">
          <!-- <GmxCheckBox v-model="item.checked" ></GmxCheckBox >
     -->
           <template v-if="props.modelValue == item.mgrSeq" >{{ item.label }}</template>
          <template v-else>{{ item.label }}</template> 
        </li>
      </ul>
    </div>
  </div>
  <div v-if="localError && error !== ''" class="error">{{ error }}</div>
</template>
<script setup>
import { computed, defineEmits, defineProps, ref, onMounted, onBeforeUnmount, watch, defineExpose } from 'vue';
//import GmxCheckBox from '../checkBox/GmxCheckBox.vue';

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  title: {
    type: String, 
    default: ''
  },
  cl : {
    type: String
  },
  items: {
    type: Array,
    default: () => { }
  },
  valid: {
    type: Function,
    default: null
  },
  modelValue: {
    type: String,
    default: ''
  },
  type : {
    type : String,
    default : ''
  },
  error : {
    type : String,
    default : ''
  },
  callBack : {
    type : Function,
    default : ()=>{}
  }
});
const localError = ref(props.error);
const rItems = ref(props.items);


onMounted(()=>{
  // 컴포넌트가 제거되기 전에 이벤트 리스너 제거
  onBeforeUnmount(() => {
    document.removeEventListener("click", absToggleOptions);
  });

  rItems.value[0].checked = true;
  
});


watch(() => props.error, (newValue) => {

// 부모에서 변경된 error 값을 감지하면 localError를 업데이트
localError.value = newValue;
});


const emit = defineEmits(['change', 'update:modalValue']);


let isOpen = ref(false);
let selectedOption = ref({mgrSeq:props.modelValue, label:props.title});

const absToggleOptions = () => {
  isOpen.value = false;
  document.removeEventListener("click", absToggleOptions);
}
//임시 조치
//값이 전달되면 리스트에 해당되는 값과 매칭하여 셀렉트 되도록 수정.
//computed로 빼야함... 또 반응이 너무 느림..
const toggleOptions = () => {
  isOpen.value = !isOpen.value;
 
  if ( isOpen.value === true) {
    setTimeout(()=>{
      document.addEventListener("click", absToggleOptions);
    }, 500);
  } else {
    document.removeEventListener("click", absToggleOptions);
  }

};

const isRead = props.type === 'read';
let itemList = "";

const computedSelectValue = computed({
  get() {

    if (props.items != null || props.items != undefined) {
      props.items.forEach(function (item) {
        if (item.label == props.modelValue) {
          selectedOption.value.mgrSeq = props.modelValue;
        }
      });
    }
    
    //selectedOption.value.mgrSeq = props.modelValue;
    
    const changeOption = (item) => {
      
      if ( isRead ) return;

      let itemArrList = [];

      //selectedOption.value.label = item.label;
      item.checked = !item.checked;
      
      //isOpen.value = false;
      

      //전체 클릭 시
      if(item.label == '전체'){
      
        itemList = '';
        rItems.value.forEach(function (_item) {
          if(item.checked){
            itemList += _item.value + ",";
            _item.checked = true;

          } else{

            _item.checked = false;

          }
        });
      } 
    
      //전체가 아닐때
      if(item.label != '전체'){
        //전체 체크 해제
        if(rItems.value[0].checked == true){
          rItems.value[0].checked = false;
        }
        // 전체가 아니면서 나머지 체크박스가 체크 된 경우.
        if(item.checked){
          itemList = '';
          rItems.value.forEach(function (_item) {
            if ( _item.checked ) {
              //label을 ','로 구분지어서 문자열 생성
              itemList += _item.value + ",";
              //길이 체크를 위한 배열에 push
              itemArrList.push(_item.label);
              
            } 
          });
        //itemArrList의 길이하고selectBox 의 총 길이-1 하고 같으면. label전체를 가진 체크박스가 체크됨.
          if(itemArrList.length == props.items.length-1){
            rItems.value[0].checked = true;
          }

        } else{
          //문자열에서 선택 해제한 체크박스 label값 제거
          itemList = itemList.replace(item.value + ",","");
          //"전체" 체크박스 체크 해제.
          if(rItems.value[0] == "전체"){
            rItems.value[0].checked = false;
          }
        }
    } 

      emit("change", props.id, itemList.slice(0,-1));
      emit('update:modelValue', itemList.slice(0,-1));

      localError.value = '';
      props.callBack(props.id, itemList.slice(0,-1));
    }

    const getSelectLabel = () => {
      let list = [];

      props.items.forEach(function (item) {
        if (item.value == props.modelValue) {

          //selectedOption.value.label = item.label;
          selectedOption.value.id =item.value;
          //selectedItem = selectedOption.value;
        }
      });

      rItems.value.forEach(function (_item) {

        if(_item.checked && _item.label != '전체'){

          list.push(_item.label)

        }

      });

      if(list.length > 1 && list[0] != ''){

        selectedOption.value.label = list[0] + " 외 " + (list.length - 1) + "개";

      } else if(list.length == 0){

        selectedOption.value.label = "전체"

      } else{

        selectedOption.value.label = list[0];

      }

      const selectedItem = selectedOption.value;
      return selectedItem;
    }
    return {
      getSelectLabel,
      changeOption
    }
  }
})

const getLabel = () => {
  
  return selectedOption.value.label;
}
defineExpose({
  getLabel
})

</script>

<style scoped lang="scss">
.gmx_selbox {
  display: flex;
  align-items: center;
  width: 100%;
  &.error-active {
        border: 1px solid $alert-color1 !important;
      }
  .col {
    flex: none;
    width: 130px;
    margin-right: 8px;
    font-size: $font-size2;

    .important {
      color: $alert-color1;
      font-size: $font-size1;
    }
  }

  .gmx_check_box{
    cursor: pointer;
    margin-right: 5px;
    height: 14px;
    width: 14px;
    border: 1px solid $gmx-box-br-color;
    border-radius: $border-radius1;
  }

  .selbox_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 34px;
    box-sizing: border-box;
    padding: 0 8px;
    border: 1px solid $gmx-box-br-color;
    border-radius: $border-radius1;
    cursor: pointer;

    .title:not(.read)  {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      font-size: $font-size2;

      &:after{
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url(@/assets/img/icn_arrow_down.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: rotate(0deg);
        transition: transform 0.3s;
      }


      &.active {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    .read {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        font-size: $font-size2;
        cursor:default
      }
    .items {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      z-index: 99;
      width: 100%;
      padding: 8px 0;
      background-color: $gmx-theme-color;
      border: 1px solid $gmx-box-br-color;
      border-radius: $border-radius1;
      overflow: hidden;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        height: 2.5em;
        box-sizing: border-box;
        padding: 0 12px;
        background-color: $gmx-theme-color;
        font-size: $font-size2;
        cursor: pointer;

        &:hover {
          background-color: $gmx-box-br-color;
        }
      }

    }
  }

  &.search {
    
    .selbox_container {
      
      height: 29px;
      border:1px solid #DCDCDC;
      border-radius: 5px;
      margin-top: 5px;
      background: #fff;

      .empty {
        
        color:$gmx-font-sub-color2
      }
      
    }
  }
}
.error {
    display: inline-block;
    margin-top: 6px;
    font-size: $font-size2;
    color: $alert-color1;
  }

  .wide {
    width: 105px;
  }

</style>
