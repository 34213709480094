<template>
  <div>
    <canvas ref="chartCanvasRef" width="600" height="600"></canvas>
  </div>
</template>

<script setup>
import { ref, toRefs, onMounted, defineProps, defineExpose, watch, computed } from 'vue';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
const props = defineProps({
  type: {
    type: String
  },
  isDisplayY2 : {
    type : Boolean
  },  
  datasets: {
    type: Array,
    default: () => [],
  },
  category: {
    type: Object,
    default: () => ({}),
  },
  yMaxValue : {
    type: Number,
  },
  yMaxPercentValue : {
    type: Number,
  }
});

const { datasets } = toRefs(props);
const chartCanvasRef = ref(null);
let chartRef = null;


// chartOptions
const chartOptions = computed(()=> {
  
  return {
  responsive: true,
  interaction: {
    mode: 'nearest',
    intersect: true,
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false // X축의 그리드 선 숨기기
      }
    },
    y1: {
      type: 'linear',
      position: 'left',
      min : 0,
      max :(props.yMaxValue/1000000) * 1.2,
      ticks: {
        stepSize: ((props.yMaxValue/1000000) * 1.2) / 10,
        beginAtZero: true,
        callback : function (value) {return Math.floor(value)}
      },
    },
    y2: {
      display : props.isDisplayY2,
      type: 'linear',
      position: 'right',
      min: 0,
      max: (props.yMaxPercentValue) ,
      ticks: {
        beginAtZero: true,
        autoSkip: true,
        maxTicksLimit: 20,
        stepSize: 20,
        callback: function (value) { return value + '%' },
      },
      grid: {
            drawOnChartArea: false // 이 Y축에 대한 그리드 선 숨기기
      }
    }
  },
  plugins: {
    legend: {
      display: true, // 범례 라벨 표시 여부
      position: 'bottom', // 라벨 위치 설정
      labels: {
                usePointStyle: true, // 범례를 점 모양으로 표시
                boxWidth: 6,          // 점의 크기 너비를 줄임
                boxHeight: 6,         // 점의 크기 높이를 줄임
                padding: 20, // 라벨 간의 간격 조정
                font: {
                    weight: 'bold', // 글자 폰트를 진하게
                    size: 14 // 폰트 크기 설정
                }
      },
      onHover: (event) => {
          event.native.target.style.cursor = 'pointer'; // 마우스 오버 시 커서를 포인터로 변경
      },
      onLeave: (event) => {
          event.native.target.style.cursor = 'default'; // 마우스가 떠나면 기본 커서로 변경
      },
    },
    tooltip: {
      enabled: true, // 항상 툴팁을 표시
      callbacks: {
        // 툴팁에서 각 데이터 포인트만 표시
        title: function () {
          return '';
        },
        label: function (context) {
          const label = context.dataset.label || '';
          let value = "";
          
          if (context.dataset.label === '누적 달성률') {
            value = context.raw + '%';
          } else {
            value = context.raw + '백만원';
          }
          return [label, `${value.toLocaleString()}`];

          //return `${label}\n\n${value}`;
        }
      },
      titleFont: {
        size: 16,
        weight: 'bold'
      },
      bodyFont: {
        size: 14,
        lineHeight: 1.5
      },
      padding : {
        top: 10,
        bottom: 10,
        left: 15,
        right: 15
      },
      //xPadding: 10,
      //yPadding: 10,
      cornerRadius: 5,
      usePointStyle: true, 
      boxWidth: 8, 
      boxHeight: 8, 
      boxPadding: 10,
    },
    datalabels: {
      adjustDataLabel: true,
      display: false, // 툴팁 항상 표시
      color: '#fff', // 텍스트 색상 설정
      anchor: 'center', // 데이터 레이블의 위치 설정
      align: 'center', // 데이터 레이블의 위치 설정
      font: {
        size: 13,
        weight: 'bold'
      },
      textAlign: 'center',
      offset: 0, // 테두리와 데이터 레이블 간의 간격
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 테두리 박스 배경색
      borderColor: 'black', // 테두리 박스 테두리 색상
      borderRadius: 10, // 테두리 박스의 둥근 모서리 반지름
      padding: {
        left: 6,
        right: 6,
        top: 2,
        bottom: 2,
      },
      formatter: (value, context) => {
        if (context.dataset.label === '누적 달성률') {
          return value + '%';
        }
        return value.toLocaleString() + '백만원';
      }
    }
  },
}});

// chartData의 변경을 감시
watch(datasets, () => {
  updateChart(props.datasets);
  // 변경된 데이터로 무언가를 수행하거나 업데이트할 수 있음
});


// 업데이트 함수
const updateChart = () => {
  const ctx = chartCanvasRef.value.getContext('2d');

  if (chartRef) {
    chartRef.destroy(); // 이전 차트 파괴
  }

  // 새 차트 생성
  chartRef = new Chart(ctx, {
    type: props.type,
    data: {
      labels: props.category,
      datasets: props.datasets,
    },
    options: chartOptions.value,
  });
};

onMounted(() => {
  updateChart();
});

defineExpose({
  updateChart,
});
</script>

<style>
.chartjs-datalabels {
  display: block;
  color: black;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}
</style>