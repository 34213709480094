<template>
  <div @dragover.prevent @drop="handleDrop"  @click="handleFileUpload">
    <div v-if="uploadedFiles.length === 0 && !isRead" class="gmx_file_wrap flex flex_center_3" :class="isDragged ? 'active' : ''"
      @dragover="dragHover" @drop="dragLeave">
      {{ UPLOAD_TEXT }}
    </div>
    <ul v-if="uploadedFiles.length > 0 || isRead" class="gmx_file_list" :class="isDragged ? 'active' : ''" @dragover="dragHover"
      @drop="dragLeave">
      <li v-for="(file, index) in uploadedFiles" :key="index" class="flex flex_center_1 flex_space_1">
        <span class="flex flex_center_1" @click.stop="file.fileIdx ? fileDownload(file.fileIdx) : emptyFunction">
          {{ file.fileRealNm ? file.fileRealNm : file.name }}
        </span>
        <GmxButton v-if="!isRead" @click.stop="handleFileRemove(file, index)" cl="c"></GmxButton>
      </li>
    </ul>
  </div>
  <input ref="fileInput" type="file" style="display: none" @change="handleFileInput" multiple/>
  <Teleport to="body">
    <GmxToast ref="toastRef"></GmxToast>
    <GmxConfirm ref="confirmRef"></GmxConfirm>
  </Teleport>
</template>
<script setup>
import { ref, defineExpose, watch, defineEmits, defineProps } from 'vue';
import GmxGlobal from '@/js/global';
import { UPLOAD_TEXT } from '@/js/lang.js'
import { GmxButton, GmxToast, GmxConfirm } from '..';
import RequsetApI from "@/js/request/requsetApI.js";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  fileList : {
    type: [],
    default : []
  },
  type : {
    type : String,
    default : ''
  }
});
const isRead = props.type === 'read';
const confirmRef = ref({});
const fileInput = ref();
const fileList = ref([]);
const toastRef = ref({});
const uploadedFiles = ref([]);
const saleCd = ref();
//왜자꾸지워요 ㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠ
const handleFileUpload = () => {
  if( isRead ) return;
  fileInput.value.click();
};

const handleDrop = (event) => {
  event.preventDefault();
  
  handleFiles(event.dataTransfer.files);

};

const handleFileInput = () => {
  handleFiles(fileInput.value.files);
};

//제거할게요
//index로 제거하는 방식으로 바꿨음.
const handleFileRemove = (file, index) => {

  function deleteCall() {
    let deleteIndex = index;
    uploadedFiles.value = uploadedFiles.value.filter((item, index) => {
      
      return index !== deleteIndex;
    });

    fileList.value = fileList.value.filter((item, index) => {
      
      return index !== deleteIndex;
    });

    handleFileChange(uploadedFiles.value);
  }
  
  deleteCall();  
};


//추가할게요.
const handleFiles = (files) => {
  
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    uploadedFiles.value.push(file);
    //파일에 key 값  생성 필요하요...
  }

  
  fileList.value = [...fileList.value, ...files];

  handleFileChange(uploadedFiles.value);
};

//다운로드 이벤트
const fileDownload = (fileIdx) => {
  RequsetApI.downloadFile(String(fileIdx) , (res) => {
    if( res ) {
        toastRef.value.showToast(GmxGlobal.MSG_STATE_OK, '파일 다운로드에 성공하였습니다.');
      } else {
        toastRef.value.showToast(GmxGlobal.MSG_STATE_ERROR, '파일 다운로드에 실패하였습니다.');
      }
  });
}

const emit = defineEmits(['change']);

const handleFileChange = (file) => {
  emit('change', file);
}

const getUploadedFiles = () => {
  
  return uploadedFiles.value||[];
}

const getFileList = () => {


  return fileList.value||[];
}

watch(() => {
    uploadedFiles.value = props.fileList;
    saleCd.value = props.saleCd;
});


defineExpose({
  getFileList,
  getUploadedFiles
});

// const triggerFileInput = () => {
//   $refs.fileInput.click();
// };

// 파일 리스트 드래그앤드롭 배경색
const isDragged = ref(false);

const dragHover = () => {
  isDragged.value = true;
}

const dragLeave = () => {
  isDragged.value = false;
}
</script>

<style scoped lang="scss">
.gmx_file_wrap {
  width: 100%;
  height: 100%;
  padding: 75px 0;
  border: 1px solid $gmx-box-br-color;
  border-radius: $border-radius2;
  font-family: $font-type4;
  font-size: $font-size2;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-image: url(@/assets/img/icn_upload.png);
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover,
  &.active {
    background-color: rgba($gmx-box-br-color, 0.2);
  }
}

.gmx_file_list {
  width: 100%;
  height: 176px;
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid $gmx-box-br-color;
  border-radius: $border-radius2;
  overflow: auto;

  &.active {
    background-color: rgba($gmx-box-br-color, 0.2);
  }

  >li {
    margin-bottom: 12px;
    font-size: $font-size2;
    font-family: $font-type4;
    padding:5px;
    border-radius: 4px;
    &:last-child {
      margin-bottom: 0;
    }

    >span {
      &:before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        background-image: url(@/assets/img/icn_file.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    ::v-deep button {
      width: 18px;
      height: 18px;
      background-image: url(@/assets/img/icn_trash.png);
    }
  }
  >li:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: $gmx-sub-color;
  }
}
</style>