<template>
  <div>
    <canvas ref="chartCanvasRef"></canvas>
  </div>
</template>

<script setup>
import { ref, toRefs, onMounted, defineProps, defineExpose, watch } from 'vue';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
const props = defineProps({
  type : {
    type : String
  },
  datasets: {
    type: Array,
    default: () => [],
  },
  category: {
    type: Object,
    default: () => ({}),
  },
});

const { datasets } = toRefs(props);

const chartCanvasRef = ref(null);
let chartRef = null;

// chartOptions
const chartOptions = {

  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false, // 라벨 표시 여부
      position: 'bottom', // 라벨 위치 설정
      
    },
    tooltip: {
      enabled: true, // 항상 툴팁을 표시
      callbacks: {
        label: (context) => {
          // 데이터 단위 추가
          return context.label + ': ' + context.formattedValue + ' %';
        }
      },
      titleFont: {
        size: 16,
        weight: 'bold'
      },
      bodyFont: {
        size: 14
      },
      xPadding: 10,
      yPadding: 10,
      cornerRadius: 5,
    },
    datalabels: {
      adjustDataLabel: true,
      display: true, // 툴팁 항상 표시
      color: '#fff', // 텍스트 색상 설정
      anchor: 'center', // 데이터 레이블의 위치 설정
      align: 'center', // 데이터 레이블의 위치 설정

      font: {
        size: 13,
        weight: 'bold'
      },
      textAlign: 'center',
      offset: 0, // 테두리와 데이터 레이블 간의 간격
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 테두리 박스 배경색
      borderColor: 'black', // 테두리 박스 테두리 색상
      borderRadius: 4, // 테두리 박스의 둥근 모서리 반지름
      padding: {
        left: 6,
        right: 6,
        top: 2,
        bottom: 2,
      },
      formatter: (value, context) => {
        if ( value === 0 )  return null;
        const label = props.category[context.dataIndex];
        return label + value + '%'; // 표시할 텍스트 포맷 설정
      }
    }
  },
};

// chartData의 변경을 감시
//watch(datasets, (newChartData, oldChartData) => {
watch(datasets, () => {
  //chartData.value.datasets[0].data = newChartData;

  updateChart(props.datasets);
  // 변경된 데이터로 무언가를 수행하거나 업데이트할 수 있음
});

// 업데이트 함수
const updateChart = () => {
  const ctx = chartCanvasRef.value.getContext('2d');

  if (chartRef) {
    chartRef.destroy(); // 이전 차트 파괴
  }

  // 새 차트 생성
  chartRef = new Chart(ctx, {
    type: props.type,
    data: {
      labels: props.category,
      datasets: props.datasets,
    },
    options: chartOptions,
  });
};

onMounted(() => {
  updateChart();
});

defineExpose({
  updateChart,
});
</script>

<style>
.chartjs-datalabels {
  display: block;
  color: black;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}
</style>