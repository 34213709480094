<template>
  <div>
    <canvas ref="chartCanvasRef" height="500"></canvas>
  </div>
</template>

<script setup>
import { ref, toRefs, onMounted, defineProps, defineExpose, watch } from 'vue';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);
const props = defineProps({
  type : {
    type : String
  },
  datasets: {
    type: Array,
    default: () => [],
  },
  category: {
    type: Object,
    default: () => ({}),
  },
});

const { datasets } = toRefs(props);

const chartCanvasRef = ref(null);
let chartRef = null;

// chartOptions
const chartOptions = {

  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true, // 라벨 표시 여부
      position: 'bottom', // 라벨 위치 설정
      labels: {
                usePointStyle: true, // 범례를 점 모양으로 표시
                boxWidth: 6,          // 점의 크기 너비를 줄임
                boxHeight: 6,         // 점의 크기 높이를 줄임
                padding: 20, // 라벨 간의 간격 조정
                font: {
                    weight: 'bold', // 글자 폰트를 진하게
                    size: 14 // 폰트 크기 설정
                }
      },
      onHover: (event) => {
          event.native.target.style.cursor = 'pointer'; // 마우스 오버 시 커서를 포인터로 변경
      },
      onLeave: (event) => {
          event.native.target.style.cursor = 'default'; // 마우스가 떠나면 기본 커서로 변경
      },

    },
    tooltip: {
      enabled: true, // 항상 툴팁을 표시
      callbacks: {
        // 툴팁에서 각 데이터 포인트만 표시
        title: function () {
          return '';
        },
        label: function (context) {
          const label = context.label || '';
          let value = context.raw + '%';  
          
          return [label, `${value.toLocaleString()}`];
        }
      },
      titleFont: {
        size: 16,
        weight: 'bold'
      },
      bodyFont: {
        size: 14,
        lineHeight: 1.5
      },
      padding : {
        top: 10,
        bottom: 10,
        left: 15,
        right: 15
      },
      //xPadding: 10,
      //yPadding: 10,
      cornerRadius: 5,
      usePointStyle: true, 
      boxWidth: 8, 
      boxHeight: 8, 
      boxPadding: 10,
    },
    datalabels: {
      adjustDataLabel: true,
      display: true, // 툴팁 항상 표시
      color: '#fff', // 텍스트 색상 설정
      anchor: 'center', // 데이터 레이블의 위치 설정
      align: 'center', // 데이터 레이블의 위치 설정
      font: {
        size: 13,
        weight: 'bold'
      },
      textAlign: 'center',
      offset: 0, // 테두리와 데이터 레이블 간의 간격
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 테두리 박스 배경색
      borderColor: 'black', // 테두리 박스 테두리 색상
      borderRadius: 10, // 테두리 박스의 둥근 모서리 반지름
      padding: {
        left: 6,
        right: 6,
        top: 2,
        bottom: 2,
      },
      formatter: (value, context) => {
        //0값일 경우 표출 방지.
        if(value === 0) {
          return null;
        }

        const index = context.dataIndex; // 현재 데이터 포인트의 인덱스
        const categoryLabel = context.chart.data.labels[index]; // 카테고리 레이블 가져오기
        let val = value + '%'
        
        return [categoryLabel, `${val.toLocaleString()}`];
      }
    }
  },
};

// chartData의 변경을 감시
//watch(datasets, (newChartData, oldChartData) => {
watch(datasets, () => {
  //chartData.value.datasets[0].data = newChartData;

  updateChart(props.datasets);
  // 변경된 데이터로 무언가를 수행하거나 업데이트할 수 있음
});

// 업데이트 함수
const updateChart = () => {
  const ctx = chartCanvasRef.value.getContext('2d');

  if (chartRef) {
    chartRef.destroy(); // 이전 차트 파괴
  }

  // 새 차트 생성
  chartRef = new Chart(ctx, {
    type: props.type,
    data: {
      labels: props.category,
      datasets: props.datasets,
    },
    options: chartOptions,
  });
};

onMounted(() => {
  updateChart();
});

defineExpose({
  updateChart,
});
</script>

<style>
.chartjs-datalabels {
  display: block;
  color: black;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}
</style>