
import {
  MODULE_NAME_HOONSICK,
  MODULE_NAME_YURIM,
  MODULE_NAME_ARA,
  MODULE_NAME_SEARCH,
  //MODULE_NAME_JIWOO, 대시보드 개발 전까지 숨깁시다. TODO ahj
  MENU_SALES,
  MENU_PROJECT,
  MENU_ORDERS,
  MENU_OUTS,
  MENU_COMPANY,
  MENU_HISTORY,
  MENU_SOLUTION,
  MENU_USER,
  MENU_AUTH,
  MENU_TARGETSALES,
  MENU_FILE,
  MENU_MAIL,
  MENU_REPORT_SALES,
  MENU_REPORT_CONT,
  MENU_REPORT_BOARD,
  MENU_CLIENT,
  MENU_BIZ,
  MENU_SEARCH
} from '@/js/lang';
import routerUtil from './util/routerUtil';
import GMXGlobal from '@/js/global';


const CONTEXT_MAIN_NAME = GMXGlobal.SYSTEM_NAME;

const ROUTER_PATH_MAIN = 'main';
const ROUTER_PATH_REPORT = 'report';
const ROUTER_PATH_SYSTEM = 'system';
const ROUTER_PATH_LOGIN = 'login';
const ROUTER_PATH_SEARCH = 'search';
//const ROUTER_PATH_DASHBOARD = 'dashboard';
//코드 분리하자..
const ezoneRouter = [
  //초기 진입 화면 설정 시작
  
  {
    path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SEARCH}`,
    name: ROUTER_PATH_SEARCH,
    isMenu: true,
    meta: {
      title: MODULE_NAME_SEARCH, // 제목 설정,
    },
    components: {
      main: () => import('@/components/ezone/layout/MainView.vue')
    },
    children: [
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SEARCH}/totalSearch`,
        name: 'totalSearch',
        meta : {
          parent:MODULE_NAME_SEARCH,
          title:MENU_SEARCH,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA0100.vue'),
        }
      },
    ],
  }
  
,  
  
  { path: '/:pathMatch(.*)*', redirect: '/' },
  {
    path: '/',
    redirect : `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_LOGIN}`,
    meta:{title:'e-support-frontssss'}
  },
  // ============보고서
  {
    path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_REPORT}`,
    name: ROUTER_PATH_REPORT,
    isMenu : true,
    meta: {
      title: MODULE_NAME_ARA , // 제목 설정,
    },
    components: { 
      main : () => import('@/components/ezone/layout/MainView.vue')  
    },
    children: [
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_REPORT}/cont`,
        name: 'reportCont',
        meta : {
          parent:MODULE_NAME_ARA,
          title:MENU_REPORT_CONT,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA5000.vue'),
        }
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_REPORT}/sales`,
        name: 'reportSales',
        meta : {
          parent:MODULE_NAME_ARA,
          title:MENU_REPORT_SALES,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA5001.vue'),
        }
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_REPORT}/board`,
        name: 'reportBoard',
        meta : {
          parent:MODULE_NAME_ARA,
          title:MENU_REPORT_BOARD,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA5002.vue'),
        }
      }
    ]
  },
  // 계약현황
  {
    path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}`,
    name: ROUTER_PATH_MAIN,
    isMenu : true,
    meta: {
      title: MODULE_NAME_HOONSICK , // 제목 설정,
    },
    components: { 
      main : () => import('@/components/ezone/layout/MainView.vue')  
    },
    children: [
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/sales`,
        name: 'sales',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_SALES,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA1000.vue'),
        }
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/salesAdd`,
        name: 'salesAdd',
        components: {
          default: () => import('@/views/ezone/EOA1001.vue'),
        },
        beforeEnter: (to, from, next) => { 
           //url로 접근시 영업현황 공개여부에 따라 표출하기.
           
          routerUtil.chkSaleAddPage(to, next, from);
        },
      },      
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/project`,
        name: 'project',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_PROJECT,
          isMenu : true,
        }, 
        components: {
          default: () => import('@/views/ezone/EOA2000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/projectAdd`,
        name: 'projectAdd',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_PROJECT,
        },
        components: {
          default: () => import('@/views/ezone/EOA2001.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/orders`,
        name: 'orders',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_ORDERS,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA3000.vue'),
        },
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/ordersAdd`,
        name: 'ordersAdd',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_ORDERS,
        },
        components: {
          default: () => import('@/views/ezone/EOA3001.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/outs`,
        name: 'outs',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_OUTS,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA4000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/outsAdd`,
        name: 'outsAdd',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_OUTS,
        },
        components: {
          default: () => import('@/views/ezone/EOA4001.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/biz`,
        name: 'biz',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_BIZ,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/EOA6000.vue'),
        },
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_MAIN}/bizAdd`,
        name: 'bizAdd',
        meta : {
          parent:MODULE_NAME_HOONSICK,
          title:MENU_BIZ,
        },
        components: {
          default: () => import('@/views/ezone/EOA6001.vue'),
        },        
      },
    ]
  },
  {
    path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}`,
    name: ROUTER_PATH_SYSTEM,
    isMenu : true,
    meta: {
      title: MODULE_NAME_YURIM, // 제목 설정,
    },
    components: { 
      main : () => import('@/components/ezone/layout/MainView.vue')  
    },
    children: [
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/user`,
        name: 'user',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_USER,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG1000.vue'),
        },
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/auth`,
        name: 'auth',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_AUTH,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG2000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/history`,
        name: 'history',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_HISTORY,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG3000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/company`,
        name: 'company',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_COMPANY,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG4000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/client`,
        name: 'client',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_CLIENT,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG5000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/solution`,
        name: 'solution',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_SOLUTION,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG6000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/targetsales`,
        name: 'targetsales',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_TARGETSALES,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG7000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/file`,
        name: 'file',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_FILE,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG8000.vue'),
        },        
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_SYSTEM}/mail`,
        name: 'mail',
        meta : {
          parent:MODULE_NAME_YURIM,
          title:MENU_MAIL,
          isMenu : true,
        },
        components: {
          default: () => import('@/views/ezone/MNG9000.vue'),
        },        
      },
    ]
  },

  {
    path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_LOGIN}/`,
    name: ROUTER_PATH_LOGIN,
    components: { 
      login : () => import('@/views/ezone/EOA0000.vue')  
    },
    children: [
      {
        path: ``,
        name: ROUTER_PATH_LOGIN,
        components: {
          default: () => import('@/components/ezone/login/LoginMain.vue'),
        }
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_LOGIN}/join`,
        name: 'join',
        components: {
          default: () => import('@/components/ezone/login/LoginJoin.vue'),
        }
      },
      {
        path: `/${CONTEXT_MAIN_NAME}/${ROUTER_PATH_LOGIN}/find`,
        name: 'find',
        components: {
          default: () => import('@/components/ezone/login/LoginFind.vue'),
        }
      }
    ]
  },
  {
    path: `/${CONTEXT_MAIN_NAME}/test`,
    name: 'test',
    components: { 
      test : () => import('@/views/ezone/TEST1.vue')  
    },
  }
];

export default ezoneRouter;